import { BreakdownPage, syncTranslations } from '@driscollsinc/ggs-workbench-module-ui';
import { withOktaAuth } from '@okta/okta-react';
import { setPageTitleAction } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import Stepper from 'components/Stepper';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';
import useLang from 'hooks/v2/useLang';
import useSettlement from 'hooks/v2/useSettlement';
import { NAMESPACE } from 'i18n';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'routes/withRouter';

function WorkbenchBreakdown(props) {
    const { getReportDetails } = useSettlement(props.oktaAuth);
    const { i18n } = useTranslation();
    const { getTextTranslated } = useLang();
    const { state } = useLocation();
    const [entryColumns, setEntryColumns] = useState<string[]>([]);

    useEffect(() => {
        syncTranslations(i18n.language);
    }, [i18n.language]);

    return (
        <DrcWrapper>
            <Stepper page={'Workspace / Breakdown'} showPageTitle={false} />
            <div style={{ padding: '0 20px', height: 'calc(100% - 100px)' }}>
                <BreakdownPage
                    onError={() => {
                        console.error('An Exception occured');
                    }}
                    getTabs={async () => (state?.tabs ?? []).map((t) => ({ label: t, value: t }))}
                    getBreakdown={async (page, sorting, filter, params) => {
                        const breakdown = await getReportDetails({
                            RuleType: params.label,
                            PoolWeek: params.poolWeek,
                            PricingPool: params.PricingPool,
                            SettlementPool: params.PFQPool,
                            Offset: 0,
                            Limit: 10,
                            ...state?.filters
                        });
                        if (breakdown.Data) {
                            const parsedData = breakdown.Data;
                            setEntryColumns(parsedData.Label.sort((a, b) => a.Position - b.Position).map((l) => l.Name));
                            return { data: parsedData.Data, totalItems: parsedData.Data.length };
                        } else {
                            return { data: [], totalItems: 0 };
                        }
                    }}
                    columns={entryColumns.map((key) => ({ header: getTextTranslated(key, NAMESPACE.SETTLEMENT), accessorKey: key, size: 200 }))}
                />
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(WorkbenchBreakdown)));
