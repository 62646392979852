import { BusinessRuleActions } from 'constants/BusinessRuleActions';
import { BusinessRuleId } from 'data/BusinessRuleId';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';

export const businessRulesData: Array<any> = [
    {
        id: '1',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Active'
    },
    {
        id: '2',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Active'
    },
    {
        id: '3',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Inactive'
    },
    {
        id: '4',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Pending Approval'
    },
    {
        id: '5',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Active'
    },
    {
        id: '6',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Active'
    },
    {
        id: '7',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Active'
    },
    {
        id: '8',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Active'
    },
    {
        id: '9',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Active'
    },
    {
        id: '10',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Active'
    },
    {
        id: '11',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Active'
    },
    {
        id: '12',
        description: 'PP00081 - Baja Tapalpa Organic',
        start_date: '11/18/2023',
        end_date: '11/18/2023',
        status: 'Active'
    }
];

export const dummyDropDownList = [
    { value: '1', label: 'Non-Marketable Fruit Shipping Detail' },
    { value: '2', label: 'Packaging Issuance' },
    { value: '3', label: 'Sweet Spot Daily' },
    { value: '4', label: 'Sweet Spot Weekly' },
    { value: '5', label: 'Receipts' },
    { value: '6', label: 'Adjusted Packaging Issuance' },
    { value: '7', label: 'Adjusted Receipts' },
    { value: '8', label: 'Active Master SKU List' },
    { value: '9', label: 'ASN Accuracy Metrics' },
    { value: '10', label: 'National Sales Reimbursement (CMEX)' },
    { value: '11', label: 'Updated Variety List ' },
    { value: '12', label: 'PlantRAD - RealTime' },
    { value: '13', label: 'Grower Manifest ' },
    { value: '14', label: 'Grower Aging Outstanding Balance' },
    { value: '15', label: 'EBM Plant Aging Outstanding Balance' },
    { value: '16', label: 'Ranch Fruit Return' },
    { value: '17', label: 'Daily Weekly' },
    { value: '18', label: 'GrowerStatement' },
    { value: '19', label: 'Tableau Report Builder' },
    { value: '20', label: 'Supplemental Statement' },
    { value: '21', label: 'Canada Tax' }
];

export const BUSINESSRULE_CARD_FIELDS = {
    // For Pricing - RuleTypeId is 1
    [BusinessRuleId['PRICING_POOL_ID']]: {
        ruleType: { visible: true, value: 'Pricing', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, label: 'Rule Id', value: '', disabled: true },
        description: { visible: true, value: '', label: 'Description', styleName: 'descriptionFieldStyle', required: true },
        defaultPrice: { visible: true, value: '', label: 'Default Price', type: 'number', required: false, precision: 6 },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            required: true,
            label: 'UOM',
            type: 'select'
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Packaging Deduction - RuleTypeId is 2
    [BusinessRuleId['PACKAGING_DEDUCTION_ID']]: {
        ruleType: { visible: true, value: 'Packaging Deduction', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Id', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        defaultPackagingCost: { visible: true, value: '', required: false, label: 'Default Packaging Cost', type: 'number', precision: 6 },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            required: true,
            type: 'select'
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Driscolls Share - RuleTypeId is 3
    [BusinessRuleId['DRISCOLLS_SHARE_ID']]: {
        ruleType: { visible: true, value: 'Driscolls Share', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Type', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        sharePercent: { visible: true, value: '', required: false, label: 'Share Percent', type: 'number', precision: 6 },
        uom: {
            visible: false,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            type: 'select',
            required: false
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Packaging Credit - RuleTypeId is 5
    [BusinessRuleId['PACKAGING_CREDIT_ID']]: {
        ruleType: { visible: true, value: 'Packaging Credit', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Id', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        defaultPackagingCost: { visible: true, value: '', label: 'Default Packaging Cost', type: 'number', required: false, precision: 6 },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            type: 'select',
            required: true
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For PFQ - RuleTypeId is 4
    [BusinessRuleId['SETTLEMENT_POOL_ID']]: {
        ruleType: { visible: true, value: 'PFQ', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Id', disabled: true },
        packagingPool: {
            visible: true,
            value: '',
            options: [],
            required: true,
            type: 'select',
            label: 'Pricing Pool',
            width: 180
        },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        defaultRelativeScore: { visible: false, value: '', required: false, label: 'Default Relative Score', type: 'number', precision: 6 },
        pfq: {
            visible: true,
            value: 'Y',
            options: [
                {
                    label: 'Yes',
                    value: 'Y'
                },
                {
                    label: 'No',
                    value: 'N'
                }
            ],
            label: 'PFQ',
            type: 'toggle',
            required: true
        },
        startDate: { visible: true, value: DuDateUtilities.ToIsoDate(new Date(), false), required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },

    // For Grower Finance/ Crop Investment - RuleTypeId is 6
    [BusinessRuleId['GROWER_FINANCE_ID']]: {
        ruleType: { visible: true, value: 'Grower Finance/ Crop Investment', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Id', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        defaultPrice: { visible: false, value: '', required: false, type: 'number', precision: 6 },
        financePercent: { visible: true, value: '', required: true, label: 'Finance Percent', type: 'number', precision: 6, min: 0, max: 100 },
        harvestCost: { visible: true, value: '', required: true, label: 'Harvest Cost', type: 'number', precision: 6 },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            required: true,
            type: 'select'
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For HOMI Premium - RuleTypeId is 7
    [BusinessRuleId['HOMI_ID']]: {
        ruleType: { visible: true, value: 'HOMI Premium', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Id', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            required: true,
            type: 'select'
        },
        ruleValue: { visible: true, type: 'number', value: '0', required: false, label: 'HOMI Premium Value', precision: 6 },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Advance Payment - RuleTypeId is 8
    [BusinessRuleId['ADVANCE_PAYMENT_ID']]: {
        ruleType: { visible: true, value: 'Advance Payment', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Id', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        advanceAmount: { visible: true, value: '', required: false, label: 'Advance Amount', type: 'number', precision: 6 },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            required: true,
            type: 'select'
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Plant Recovery - RuleTypeId is 9
    [BusinessRuleId['PLANT_RECOVERY_ID']]: {
        ruleType: { visible: true, value: 'Plant Recovery', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Id', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            required: true,
            type: 'select'
        },
        ruleValue: { visible: true, value: '0', label: 'Plant Recovery Value', type: 'number', required: false, precision: 6 },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Fresh Credit - RuleTypeId is 10
    [BusinessRuleId['FRESH_CREDIT_ID']]: {
        ruleType: { visible: true, value: 'Fresh Credit', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Type', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            required: true,
            type: 'select'
        },
        ruleValue: { visible: true, value: '0', required: false, label: 'Fresh Credit Value', type: 'number', precision: 6 },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Assessment - RuleTypeId is 11
    [BusinessRuleId['ASSESSMENT_ID']]: {
        ruleType: { visible: true, value: 'Assessment', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Id', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            required: true,
            type: 'select'
        },
        ruleValue: { visible: true, value: '0', label: 'Assessment Value', type: 'number', required: false, precision: 6 },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Packaging Issuance - RuleTypeId is 12
    [BusinessRuleId['PACKAGING_ISSUANCE_ID']]: {
        ruleType: { visible: true, value: 'Packaging Issuance', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Id', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        defaultPackagingCost: { visible: true, value: '', required: false, type: 'number', precision: 6 },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            type: 'select',
            required: true
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For HOMI Deduction - RuleTypeId is 13
    [BusinessRuleId['HOMI_DEDUCTION_ID']]: {
        ruleType: { visible: true, value: 'HOMI Deduction', disabled: true },
        ruleId: { visible: true, value: '', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            type: 'select',
            required: true
        },
        ruleValue: { visible: true, value: '0', required: false, type: 'number', precision: 6 },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Advance Reversal - RuleTypeId is 14
    [BusinessRuleId['ADVANCE_REVERSAL']]: {
        ruleType: { visible: true, value: 'Advance Reversal', disabled: true },
        ruleId: { visible: true, value: '', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        defaultPrice: { visible: true, value: '', label: 'Default Price', type: 'number', required: false, precision: 6 },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            type: 'select',
            required: true
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For PFQ Adjustments - ALL ranches,All items and All Brands - RuleTypeId is 15
    [BusinessRuleId['PFQ_ADJUSTMENT_ID']]: {
        ruleType: { visible: true, value: 'PFQ Adjustment', disabled: true },
        ruleId: { visible: true, value: '', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        defaultPrice: { visible: true, value: '', label: 'Default Price', type: 'number', required: false, precision: 6 },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            type: 'select',
            required: true
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Adjustment Premium - RuleTypeId is 16
    [BusinessRuleId['ADJUSTMENT_PREMIUM_ID']]: {
        ruleType: { visible: true, value: 'Misc Adjustment', disabled: true },
        ruleId: { visible: true, value: '', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        rateType: {
            visible: true,
            value: 'Amount',
            options: [
                { value: 'Amount', label: 'Amount' },
                { value: 'AmountPerUnit', label: 'Amount Per Unit' }
            ],
            label: 'Rate Type',
            type: 'select',
            required: true
        },
        defaultPrice: { visible: true, value: '', label: 'Value', type: 'number', required: false, precision: 6, min: -1000, max: 1000 },
        uom: {
            dependent: 'rateType',
            dependentValue: 'AmountPerUnit',
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            type: 'select',
            required: true
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For Adjustment Premium - RuleTypeId is 17
    [BusinessRuleId['ADJUSTMENT_DEDUCTION_ID']]: {
        ruleType: { visible: true, value: 'Adjustment Deduction', disabled: true },
        ruleId: { visible: true, value: '', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        defaultPrice: { visible: true, value: '', label: 'Rate per UOM', type: 'number', required: false, precision: 6 },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            type: 'select',
            required: true
        },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' },
        rejectReason: { visible: false, value: '', label: 'Reject Reason', styleName: 'descriptionFieldStyle' }
    },
    // For default
    default: {
        ruleType: { visible: true, value: '', label: 'Rule Type', disabled: true },
        ruleId: { visible: true, value: '', label: 'Rule Id', disabled: true },
        description: { visible: true, value: '', required: true, label: 'Description', styleName: 'descriptionFieldStyle' },
        uom: {
            visible: true,
            value: 'LB',
            options: [
                { value: 'LB', label: 'LB' },
                { value: 'Crates', label: 'Crates' }
            ],
            label: 'UOM',
            required: true,
            type: 'select'
        },
        ruleValue: { visible: true, value: '0', required: false, label: 'Rule Value', type: 'number', precision: 6 },
        startDate: { visible: true, value: undefined, required: true, label: 'Start Date', type: 'date' },
        endDate: { visible: true, value: undefined, label: 'End Date', type: 'date' },
        status: { visible: true, value: '', label: 'Status' },
        createdBy: { visible: true, value: '', type: 'tooltip' },
        createdDateTime: { visible: true, value: '', type: 'tooltip' },
        modifiedBy: { visible: true, value: '', type: 'tooltip' },
        modifiedDateTime: { visible: true, value: '', type: 'tooltip' },
        approver: { visible: true, value: '', type: 'tooltip' },
        approvedDateTime: { visible: true, value: '', type: 'tooltip' }
    }
};

export const businssRulePayload = (businessRulesDetails: any, bu: string, loggedInUser: string) => {
    const payload = {
        Action: BusinessRuleActions.CREATE,
        BusinessRule: {
            RuleTypeId: parseInt(businessRulesDetails.RuleType),
            BusinessUnit: bu,
            RuleName: businessRulesDetails.Description,
            Status: businessRulesDetails.Status,
            StartDate: businessRulesDetails.StartDate ? new Date(businessRulesDetails.StartDate).toISOString().split('T')[0] : '',
            EndDate: businessRulesDetails.EndDate ? new Date(businessRulesDetails.EndDate).toISOString().split('T')[0] : '',
            UOM: businessRulesDetails.UOM,
            PFQFlag: businessRulesDetails?.PFQ,
            Formula: [
                {
                    CreatedBy: loggedInUser,
                    CreatedDateTime: new Date().toISOString(),
                    FormulaSeq: 1,
                    FormulaType: businessRulesDetails.ForumulaType,
                    CurrencyCode: 'USD',
                    FormulaValue: parseFloat(businessRulesDetails.FormulaValue)
                },
                businessRulesDetails.financePercent && {
                    CreatedBy: loggedInUser,
                    CreatedDateTime: new Date().toISOString(),
                    FormulaSeq: 1,
                    FormulaType: 'Percent',
                    CurrencyCode: 'USD',
                    FormulaValue: businessRulesDetails.FinancePercent
                }
            ].filter((item) => {
                return item && item.FormulaSeq;
            }),
            Assignment: {
                ranches: businessRulesDetails.Ranches.split(','),
                items: businessRulesDetails.Items.split(','),
                CreatedBy: loggedInUser,
                CreatedDateTime: new Date().toISOString()
            }
        }
    };
    return payload;
};
